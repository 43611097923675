import React from "react"
import Layout from "../components/layout/layout"
import Box from "@material-ui/core/Box"
import SEO from "../components/seo"
import Form from "../components/form"
import Header from "../components/header"
import MobileNav from "../components/mobileNav"

const Contact = () => {
  return (
    <>
      <SEO title="Contact" />
      <Header />
      <MobileNav />
      <Layout>
        <Box mx="auto" my={4} width={8 / 10}>
          <Box my={4}>
            <Box
              fontSize="h2.fontSize"
              fontWeight={500}
              letterSpacing={2}
              lineHeight={1.5}
            >
              Let&#39;s Start a Conversation
            </Box>
            <Box fontSize="h5.fontSize" letterSpacing={1.5} lineHeight={1.5}>
              While we're good with Morse Code, there are simpler ways for us to
              get in touch and answer your questions. Complete the form below
              and share some information about your project and we will be in
              touch today!
            </Box>
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            flexDirection="row"
            justifyContent="space-between"
            my={5}
          >
            <Box width={{ sm: 1, md: 4 / 10 }}>
              <Form />
            </Box>
            <Box width={{ sm: 1, md: 4 / 10 }} textAlign="left">
              <Box
                fontSize="h5.fontSize"
                letterSpacing={1.5}
                lineHeight={1.5}
                mt={{ xs: 7, sm: 7, md: 0 }}
              >
                We are excited that you found us. We are real people, we love
                building businesses and helping our customers and partners
                thrive.
                <br />
                <br />
                Fill out the form or tired of filling out forms?
              </Box>
              <Box
                letterSpacing={1.5}
                lineHeight={1.5}
                fontSize="h5.fontSize"
                pt={2}
              >
                Call us at: 717-649-3287 or send us an email at
                hello@thedashboardcompany.com
                <br />
                <br />
                Looking forward to working with you. Thanks for visiting!
              </Box>
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  )
}

export default Contact
