import React from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  input: {
    width: "100%",
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
  label: {
    fontSize: "1.25rem",
    padding: theme.spacing(1),
  },
  formButton: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    letterSpacing: "0.15rem",
    fontSize: "1.25rem",
    margin: theme.spacing(1),
  },
}))

export default function Form() {
  const classes = useStyles()
  return (
    <Box>
      <form
        name="contact"
        method="post"
        action="/thanks"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <div>
          <label htmlFor="name" className={classes.label}>
            Name
          </label>
          <input type="text" name="name" id="name" className={classes.input} />
        </div>
        <div>
          <label htmlFor="company" className={classes.label}>
            Company
          </label>
          <input
            type="text"
            name="company"
            id="company"
            className={classes.input}
          />
        </div>
        <div>
          <label htmlFor="name" className={classes.label}>
            Title/ Position
          </label>
          <input
            type="text"
            name="title"
            id="title"
            className={classes.input}
          />
        </div>
        <div>
          <label htmlFor="email" className={classes.label}>
            Email
          </label>
          <input
            type="text"
            name="email"
            id="email"
            className={classes.input}
          />
        </div>
        <div>
          <label htmlFor="phone" className={classes.label}>
            Phone
          </label>
          <input
            type="text"
            name="phone"
            id="phone"
            className={classes.input}
          />
        </div>
        <div>
          <label htmlFor="message" className={classes.label}>
            About Your Project
          </label>
          <textarea
            name="message"
            id="message"
            rows="10"
            required
            className={classes.input}
          />
        </div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          className={classes.formButton}
        >
          Send
        </Button>
      </form>
    </Box>
  )
}
